import Footer from "./Footer";
import HeroWhite from "./Image/Logo/HeroWhite";
import LogoWhite from "./Image/Logo/White";
import PageContainer from "./Page/Container";

export default function Holding() {
  return (
    <>
      <div className="min-h-full">
        <div className="brand-hero relative overflow-hidden">
          <div className="relative z-10 mx-auto 2xl:container sm:px-6 lg:px-8">
            <div className="flex h-20 items-center justify-between px-4 sm:px-0">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a href="/">
                    <LogoWhite />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <header className="relative z-10 pb-4 pt-4 md:pb-14 md:pt-8">
            <div className="mx-auto max-w-2xl px-4 text-center text-base text-white 2xl:container sm:px-6 md:text-lg lg:px-8">
              <h1 className="mb-3 text-3xl font-semibold md:mb-6 md:text-5xl">
                We'll be back soon!
              </h1>
            </div>
          </header>

          <div className="z-o absolute -top-1/3 flex w-full items-center justify-center overflow-hidden md:top-0">
            <HeroWhite />
          </div>
        </div>

        <main className="my-8 lg:overflow-visible">
          <div className="mx-auto px-4 py-0 pb-12 2xl:container sm:px-6 md:py-4 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-2xl ">
              <div className="flex flex-col space-y-8 md:space-y-10">
                <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
                  <div className="px-2 sm:mx-auto sm:w-full sm:px-0">
                    <PageContainer>
                      <p className="text-center text-lg font-medium">
                        We're making a few updates and will be back soon.
                      </p>
                    </PageContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
