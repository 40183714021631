import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom/client";

import "css/App.css";

import { initAnalytics } from "analytics";
import Holding from "components/Holding";
import reportWebVitals from "reportWebVitals";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
  });
}

initAnalytics();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Holding />);

reportWebVitals();
